import { Outlet } from "react-router-dom";

export default function IdMe() {
  return (
    <div>
      <h1 className="m-5 bg-white rounded-xl p-5 text-4xl font-bold tracking-tight text-green-500 sm:text-5xl">
        ID.Me Test Page
      </h1>
      <a href="https://api.id.me/oauth/authorize?client_id=6a9dedb9ffb1e6ccd62a9d4b42561146&redirect_uri=https://makeit.red/idme/callback&response_type=code&scope=kba_replacement">
        <button class="mb-5 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
          Click to Authenticate
        </button>
      </a>
      <Outlet />
    </div>
  );
}
