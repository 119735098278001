import { useSearchParams } from "react-router-dom";

export default function Callback() {
  const [searchParams] = useSearchParams();
//   console.log(searchParams.toString());
//   console.log(searchParams.get('code'));
  return (
    <div>
      Callback Recieved!
      <br />
      <h2> Your code is: </h2>
      {searchParams.get('code')}
    </div>
  );
}
